// import html from '../../assets/html5.png';
import css from "../../assets/css3.png";
import java from "../../assets/java.png";
import django from "../../assets/django.png";

const html =
  "https://sunilband.netlify.app/_ipx/w_1200,q_75/https%3A%2F%2Fi.ibb.co%2FZXpZGFG%2FScreenshot-2023-07-07-223352.png?url=https%3A%2F%2Fi.ibb.co%2FZXpZGFG%2FScreenshot-2023-07-07-223352.png&w=1200&q=75";
const python =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png";
const js =
  "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png";
const android =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Android_Studio_Icon_3.6.svg/1900px-Android_Studio_Icon_3.6.svg.png";
const next =
  "https://res.cloudinary.com/startup-grind/image/upload/c_fill,dpr_2.0,f_auto,g_center,q_auto:good/v1/gcs/platform-data-dsc/events/nextjs-boilerplate-logo.png";
const tailwind =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/1024px-Tailwind_CSS_Logo.svg.png";
const MongoDB =
  "https://pbs.twimg.com/profile_images/1452637606559326217/GFz_P-5e_400x400.png";
const ts =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png";

const data = [
  {
    name: "ProfilixerPro (Under Development)",
    builder: [html, next, ts, MongoDB],
    desc: "This project is a clone for the app called as Mythic-codex. I have created the frontend of the homepage of the application.Next.js-based AI assessment platform offers seamless integration of machine learning algorithms for efficient evaluation and personalized feedback in diverse learning scenarios.",
    github: "https://profilize-pro.saurabhtalele.tech",
  },
  {
    name: "Mythic-codex Clone (Under Development)",
    builder: [html, next, ts],
    desc: "This project is a clone for the app called as Mythic-codex. I have created the frontend of the homepage of the application.",
    github: "https://mythic-codex.saurabhtalele.tech",
  },
  {
    name: "Toools.design Clone",
    builder: [html, next, ts],
    desc: "This project is a clone for the app called as toools.design. I have created the frontend of the homepage of the application.",
    github: "https://toools-design-clone.vercel.app/",
  },
  {
    name: "ResuBuilder",
    builder: [html, next, js, MongoDB],
    desc: "ResuBuilder is a AI based Resume Builder application build on Next.js and has MongoDB as the database. It is a smart application that suggests you the right way to build your resume. It uses AI to enhance the user interaction.",
    github: "https://resu-builder.vercel.app/",
  },
  {
    name: "Decision GPT",
    builder: [html, next, tailwind, js],
    desc: "This application is based on ChatGPT API which guides to take the decisions. This app is built using NextJs with the interation of tailwind CSS for the styling of different components within the app.",
    github: "https://decision-gtp.vercel.app/",
  },
  {
    name: "GFG Students chapter GHRCEM",
    builder: [html, next, tailwind, MongoDB],
    desc: "This is a web application made for the GFG Students Chapter GHRCEM. This app is built using NextJs with the interation of tailwind CSS for the styling of different components within the app. MongoDB is used as the database for storing the data in the backend.",
    github: "https://gfg-ghrcem.vercel.app/",
  },
  {
    name: "Tours and Travels App",
    builder: [html, css, js, django],
    desc: "The application is used for booking the packages for the tours. It is build using HTML, CSS and JavaScript. The backend is build in django framework. The database used here is the MySQL.",
    github: "https://github.com/SaurabhATalele/Tours-Management-System-.git",
  },

  {
    name: "Android App for PIB",
    builder: [python, django, android, java],
    desc: "The application is built for the Press Information Beauro of India. The app is built using the Android Studio and the backend is build in django framework. The database used here is the firebase database and the messaging is done by the same.",
    github: "https://github.com/SaurabhATalele/PIB-News-Extractor",
  },
  {
    name: "Web Based Cloud App",
    builder: [python, django, html, css, js],
    desc: "This is a web application that can store your data on the cloud that has various backup options and users can access thier data from anywhere they want to.",
    github: "https://github.com/SaurabhATalele/cloud-app-using-django",
  },
];

export default data;
