import React from "react";
import data from "./Data/ExperienceData";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination, Scrollbar } from "swiper";

// import "./CSS/Projects.css";
const Experience = () => {
  const items = data;

  return (
    <div className="pages projects" id="projects">
      <h2 className="heading">Experience</h2>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {items.map((elem) => {
          const { name, Location, Position, Duration, Description, Logo } =
            elem;
          return (
            <div key={items.indexOf(elem)}>
              <SwiperSlide>
                <div
                  target="_blank"
                  className="dec-none"
                  style={{ borderRadius: "10px" }}
                >
                  <h2 className="project-heading text-light">{Position}</h2>
                  <div className="tech">
                    <img
                      src={Logo}
                      alt="Logo"
                      style={{
                        maxHeight: "60px",
                        width: "auto",
                        maxWidth: "100px",
                        margin: "10px",
                      }}
                    />
                    <div
                      className="build-with text-yellow font-light"
                      style={{ fontSize: "1.7rem" }}
                    >
                      {name}
                    </div>
                  </div>

                  <div className="pd">
                    <div
                      className="desc-hl text-yellow"
                      style={{ fontWeight: "light", color: "yellow" }}
                    >
                      {Location}
                    </div>
                    <div
                      className="pdb text-light"
                      style={{ fontSize: "1.5rem" }}
                    >
                      {Duration}
                      <div
                        className=" text-light"
                        style={{ fontSize: "1.2rem", paddingTop: "1rem" }}
                      >
                        {Description}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Experience;
