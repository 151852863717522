import React from "react";
import data from "./Data/ProjectsData";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

import "./CSS/Projects.css";
const Projects = () => {
  const items = data;

  return (
    <div className="pages projects" id="projects">
      <h2 className="heading">Projects</h2>

      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {items.map((elem) => {
          const { name, builder, desc, github } = elem;
          return (
            <div key={items.indexOf(elem)}>
              <SwiperSlide>
                <a
                  href={github}
                  target="_blank"
                  className="dec-none"
                  rel="noreferrer"
                >
                  <h2 className="project-heading text-light">{name}</h2>
                  <div className="tech">
                    <div className="build-with text-yellow font-light">
                      Build With
                    </div>
                    <div className="stack">
                      {
                        // mapping the languages
                        builder.map((lang) => {
                          return (
                            <img
                              key={builder.indexOf(lang)}
                              src={lang}
                              alt="image"
                            />
                          );
                        })
                      }
                    </div>
                  </div>

                  <div className="pd">
                    <div className="desc-hl text-yellow">Description</div>
                    <div className="pdb text-light">{desc}</div>
                  </div>
                </a>
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Projects;
