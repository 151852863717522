
import { Typewriter } from 'react-simple-typewriter'

const TypeWriter = () =>
{
    return(
        <Typewriter
        words={["Console.log('Hello Coders')","Life is all about 0s and 1s","<Programmer/>",'while True: grind()']}
        loop={Infinity}
        cursor
        cursorStyle='_'
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={1000}                                                 
      /> 
    )
}

export default TypeWriter;