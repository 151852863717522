import "./App.css";
import Container from "./Components/Container";
import Nav from "./Components/Nav";

function App() {
  return (
    <div className="App">
    <Nav/>
    <Container/>

    </div>
  );
}

export default App;