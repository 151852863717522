import image from "../assets/Profile.jpg"
import './CSS/About.css'
import animation from '../assets/animation.gif'

import { motion } from "framer-motion";

export default function About() {



  return (
    <div className="pages "  id="about">
        <h1 className="heading">ABOUT</h1>

    <motion.div
    initial={
      { scale:0.4}
    }
    transition={{
      duration:1
    }}
   
    whileInView={{
      scale:1
    }}
  
    className="about" >

    <img src={image} alt="image" className="image"/>
    {/* <div className="Education">
    <h2>Education</h2> */}
      
{/* <div class="timeline">
  <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
    <div class="timeline__event__icon ">
      <i class="lni-cake"></i>
      <div class="timeline__event__date">
        2021-Current  <br /> CGPA: 9.32
      </div>
    </div>
    <div class="timeline__event__content ">
      <div class="timeline__event__title">
        B.Tech - Infromation Technology
      </div>
      <div class="timeline__event__description">
        <p>G. H. Raisoni Institute of Engineering and Technology, Pune</p>
      </div>
    </div>
  </div>
  <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
    <div class="timeline__event__icon">
      <i class="lni-burger"></i>
      <div class="timeline__event__date">
        2018-2021<br /> Percentage: 92.26%
      </div>
    </div>
    <div class="timeline__event__content">
      <div class="timeline__event__title">
        Diploma in Information Technology
      </div>
      <div class="timeline__event__description">
        <p>Government Polytechic, Amravati (3 Year Diploma after SSC)</p>
      </div>
    </div>
  </div>




  <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
    <div class="timeline__event__icon">
      <i class="lni-burger"></i>
      <div class="timeline__event__date">
       2018 <br /> Percentage: 92.00%
      </div>
    </div>
    <div class="timeline__event__content">
      <div class="timeline__event__title">
        SSC
      </div>
      <div class="timeline__event__description">
        <p>Jagjivandas English  School, Muktainagar (Maharashtra State Board)</p>
      </div>
    </div>
  </div>
 
</div> */}
    {/* </div> */}
    
    <div className="about-data text-light">
     Welcome to my digital workspace! I'm Saurabh Talele, a passionate software developer with a keen eye for creating seamless and innovative web experiences. With expertise in cutting-edge technologies such as Next.js, React.js, Python, Node.js, MongoDB, MySQL, and more, I thrive on turning ideas into reality through clean, efficient, and scalable code.

My journey in the realm of software development has equipped me with a diverse skill set, enabling me to tackle challenges across the entire web development stack. Whether it's crafting responsive and intuitive user interfaces with React.js, building robust backend solutions with Node.js and Python, or designing database architectures using MongoDB and MySQL, I'm committed to delivering high-quality solutions that exceed expectations.
   </div> 
    </motion.div>
      
    </div>
  )
}
