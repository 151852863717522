import "./CSS/Contact.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaMailBulk,
} from "react-icons/fa";

export default function Contact() {
  const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById("Name").value;
    const email = document.getElementById("Email").value;
    const tel = document.getElementById("tel").value;
    const sub = document.getElementById("sub").value;
    const message = document.getElementById("message").value;
    const templateParams = {
      name,
      email,
      subject: sub,
      tel,
      message,
    };
    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", {
        service_id: "service_3celuy5",
        template_id: "template_gwo5l2c",
        user_id: "rmfnb8Um1L7hDf7sC",
        template_params: templateParams,
        accessToken: "WbJz5zf-Iz8AWiCR2QYvy",
      })
      .then((response) => {
        console.log(response);
        toast.success("Mail Sent We Will Contact You", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        toast("Mail Not Sent Try Again");
      });
  };
  return (
    <div className="pages contact-page" id="connect">
      <div className="toast">
      <ToastContainer />
      </div>
      <h1 className="page__heading">Contact</h1>

      <div className="contact-form">
        <div className="cf">
          <form>
            <div className="field">
              <label className="text-light">Name</label>

              <input
                type="text"
                name="Name"
                id="Name"
                placeholder="Name"
              ></input>
            </div>

            <div className="field">
              <label className="text-light">Email</label>
              <input
                type="email"
                name="email"
                id="Email"
                placeholder="Email"
                required
              ></input>
            </div>

            <div className="field">
              <label className="text-light">Phone No</label>
              <input
                type="tel"
                name="tel"
                id="tel"
                placeholder="Phone No"
                required

              ></input>
            </div>

            <div className="field">
              <label className="text-light">Subject</label>

              <input
                type="text"
                name="sub"
                id="sub"
                placeholder="Subject"
              ></input>
            </div>
            <div className="field">
              <label className="text-light">Message</label>
              <textarea
                type="text"
                className="Message"
                rows={3}
                name="Message"
                id="message"
                placeholder="Message"
              ></textarea>
            </div>

            <button className="submit-btn bg-yellow" onClick={handleSubmit}>
              Submit
            </button>
          </form>
        </div>

        <div className="socials">
          <a href="https://www.instagram.com/saurabhtalele1122/">
            {/* <img src={Insta} alt="Instagram"/> */}
            <FaInstagram />
          </a>
          <a href="https://github.com/SaurabhATalele">
            {/* <img src={Gh} alt="GitHub"/> */}
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/saurabh-talele-6558601b1/">
            {/* <img src={linkedin} alt="LinkedIn"/>
             */}
            <FaLinkedin />
          </a>
          {/* <a href="mailto:saurabhatalele@gmail.com">
    <img src={Mail} alt="Mail"/>
    <FaMailBulk/>
    </a> */}
          <a href="https://www.facebook.com/SaurabhTalele.1122">
            {/* <img src={Fb} alt="Facebook"/> */}
            <FaFacebook />
          </a>
        </div>
      </div>

      <footer className="text-light d-flex">
        This project is Build using React JS, HTML, CSS, Framer Motion,
        EmailJs,etc.
      </footer>
    </div>
  );
}
