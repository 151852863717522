const Data = [
  {
    name: "Cryptonet Technologies",
    Logo: "https://www.cryptonet.technology/static/media/CryptonetLogo.1910bae781fb8be700f3.png",
    Location: "Remote",
    Position: "Frontend Developer Intern",
    Duration: "February 2024 - present",
    Description:
      "Working on the frontend of the website. The tech stack used is ReactJS, NextJs, HTML, CSS, and JavaScript.",
  },
  {
    name: "GetDefault",
    Logo: "https://assets-global.website-files.com/61a9d163662d2084d7cbc38f/61ccb318caa26651e5b2c88a_white_transparent_logo.svg",
    Location: "Remote",
    Position: "Automation Developer Intern",
    Duration: "May 2023 - November 2023",
    Description:
      "Working on the automations. The tech stack used is Python, Selenium, and Django.",
  },
];

export default Data;
